 /* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

const LoginForm = () => {
    return (
        <div className="login-form">
            <h2> Account Management</h2>

            <form action="http://ugo.cobranet.org:81/radauth.php" method="POST" target="_blank" >
                <div className="form-group">
                    <label>User Id (EID or TC)</label>
                    <input type="text" className="form-control" name="login" placeholder="User Id (EID or TC)" required />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" name="pass" placeholder="Password" required />
                </div>

                <button type="submit">Sign In Now</button>
            </form>
        </div>
    )
}

export default LoginForm