 /* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

const RegisterForm = () => {
    return (
        <div className="register-form">
            <h2>Go To Webmail</h2>

            <form action="http://webmail.cobranet.org/atmail.php" method="POST" target="_blank" name="frmwebmail">
                <div className="form-group">
                    <label>E-mail</label>
                    <input type="text" name="username" className="form-control"
                        style={{
                            width: '60%',
                            display: 'inline-block'
                        }}
                    />
                    <select name="pop3host" className="form-control"
                        style={{
                            width: '40%',
                            display: 'inline-block',
                            top: '1px',
                            position: 'relative'
                        }}
                    >
                        <option value="cobranet.ng">cobranet.ng</option>
                        <option value="cobranet.org">cobranet.org</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" placeholder="Password" />
                </div>

                <div className="form-group">
                    <label>Interface</label>
                    <select name="LoginType" className="form-control">
                        <option value="simple">Basic (Any Browser)</option>
                        <option value="ajax">Simple (Ajax Browser)</option>
                        <option value="xp">Advanced (IE/FF)</option>
                    </select>
                </div>
                <input name="language" type="hidden" value="english" />
                <input name="MailServer" type="hidden" value="cobranet.org" />
                <input name="MailType" type="hidden" value="pop" />

                <button type="submit">Login</button>
            </form>
        </div>
    )
}

export default RegisterForm